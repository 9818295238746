<template>
	<v-dialog :value="true" min-width="300" max-width="600" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title class="modal-title">Debug</v-card-title>
			<v-card-text>
				<json-visualizer :val="value" :root="root"></json-visualizer>
			</v-card-text>
			<v-card-actions>
				<v-btn @click="modal.trigger('close')" class="ml-auto">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import jsonVisualizer from "../../components/jsonVisualizer";

	export default {
		name: "debug",

		props: {
			modal: {
				type: Object,
				required: true,
			},
			value: {
				type: Object,
				required: true,
			},
			root: {
				type: String,
				default: "",
			},
		},

		components: {
			jsonVisualizer,
		},
	};
</script>

<style scoped lang="scss">
</style>