<template>
	<div class="json-visualizer">
		<div class="path mb-4">
			<v-btn small :disabled="!path.length" @click="back">Back</v-btn> {{prettyPath}}
		</div>

		<div v-if="type == 'string' || type == 'number' || type == 'function'">
			<div class="form-control mb-3">{{current}}</div>
		</div>

		<div v-if="type == 'boolean'" class="input-group input-group-sm">
			<div class="form-control mb-3">{{current ? 'TRUE' : 'FALSE'}}</div>
		</div>

		<template v-if="type == 'object'">
			<div v-for="(item, ndx) in current" :key="ndx" class="input-group input-group-sm">
				<div class="mb-1">{{ndx}}:</div>
				<div class="form-control mb-3" @click="go(ndx)">
					{{toString(item)}}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		name: "json-visualizer",

		props: {
			val: {
				type: [Object, Array, String, Boolean, Number],
			},

			root: {
				type: String,
				default: "",
			},
		},

		data() {
			return {
				path: [],
			};
		},

		computed: {
			current() {
				let item = this.val;

				for (let i = 0; i < this.path.length; i++) {
					if (typeof item[this.path[i]] === "undefined") {
						this.path = this.path.slice(0, i);
						return item;
					}

					item = item[this.path[i]];
				}

				return item;
			},

			type() {
				return typeof this.current;
			},

			prettyPath() {
				let path = this.root;
				if (this.path.length) {
					path += ".";
				}

				return path + this.path.join(".");
			},
		},

		methods: {
			back() {
				if (this.path.length) {
					this.path.pop();
				}
			},

			go(ndx) {
				this.path.push(ndx);
			},

			toString(item) {
				if (typeof item === "object") {
					let res = JSON.stringify(item);
					if (res.length > 200) {
						res = res.substr(0, 200) + "...";
					}

					return res;
				}

				return item;
			},
		},
	};
</script>

<style scoped lang="scss">
	.json-visualizer {
		font-family: monospace;
	}
	.form-control {
		word-break: break-word;
		cursor: pointer;
		font-size: 12px;
		height: auto;
		border: 1px solid grey;
	}
</style>